<template>
	<div v-if="isMobile==0">
		<el-dialog class="exchange_dialog" :visible.sync="showDialog" :show-close="false" @close="close">
			<div class="exchange_form">
				<div class="head">
					<span class="title">{{$t('common.exchangeSelect')}}</span>
					<el-input
						class="search"
						:placeholder="$t('common.keyword')"
						suffix-icon="el-icon-search"
						v-model="searchValue"
						@keyup.enter.native="handleSearch"
					/>
				</div>
				<div class="cont">
					<div class="cont_box" v-show="!isSearch">
						<div
							class="list"
							v-for="(item,index) in webList"
							:key="index"
						>
							<div class="title">{{item.name}}</div>
							<div class="taglist">
								<span
									v-for="(it,subindex) in item.list"
									:key="subindex"
									:class="{select:web==it.web}"
									@click="handleSelect(it.name, it.web, it.id,it.web_type)"
								>{{it.name}}</span>
							</div>
						</div>
					</div>
					<!-- 搜索结果 -->
					<div class="cont_box2" v-if="isSearch">
						<span
							class="item"
							v-for="(it,subindex) in searchList"
							:key="subindex"
							:class="{select:web==it.web}"
							@click="handleSelect(it.name, it.web, it.id,it.web_type)"
						>{{it.name}}</span>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
	<div v-else-if="isMobile==1" class="actionSheet">
		<van-action-sheet 
		v-model="showDialog" 
		:title="$t('common.exchangeSelect')" 
		:closeable="false"
		:cancel-text="$t('common.cancel')"
		@closed="close">
			<div class="content_phone">
				<div class="search_box">
					<van-search 
					v-model="searchValue" 
					shape="round"
					background="transparent"
					:placeholder="$t('common.keyword')"
					@search="handleSearch" />
				</div>
				<div class="cont_box" v-show="!isSearch">
					<div
						class="commonly"
						v-for="(item,index) in webList"
						:key="index"
					>
						<div class="title"
							v-if="item.name=='常用'||item.name=='Commonly Used'"
						>{{item.name}}</div>
						<div class="taglist"
							v-if="item.name=='常用'||item.name=='Commonly Used'"
						>
							<span
								v-for="(it,subindex) in item.list"
								:key="subindex"
								:class="{select:web==it.web}"
								@click="handleSelect(it.name, it.web, it.id,it.web_type)"
							>{{it.name}}</span>
						</div>
					</div>
				</div>
				<div class="index_box" v-show="!isSearch">
					<van-index-bar :index-list="indexList">
						<template v-for="(item,index) in webList">
							<van-index-anchor 
							v-if="item.name!='常用'&&item.name!='Commonly Used'" 
							:key="index"
							:index="item.name" />
							<template v-if="item.name!='常用'&&item.name!='Commonly Used'">
								<van-cell 
								v-for="(it,subindex) in item.list"
								:key="index+'_'+subindex" :title="it.name"
								@click="handleSelect(it.name, it.web, it.id,it.web_type)" />
							</template>
						</template>
					</van-index-bar>
				</div>
				<!-- 搜索结果 -->
				<div class="cont_box" v-if="isSearch">
					<div class="commonly">
						<div class="taglist">
							<span
								v-for="(it,subindex) in searchList"
								:key="subindex"
								:class="{select:web==it.web}"
								@click="handleSelect(it.name, it.web, it.id,it.web_type)"
							>{{it.name}}</span>
						</div>
					</div>
				</div>
			</div>
		</van-action-sheet>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex'
	export default ({
		name: 'exchange',
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			web: {
				type: String,
				default: null,
			},
		},
		computed:{
			...mapGetters(['webList','isMobile']),
			indexList(){
				var arr=[]
				this.webList.forEach(item=>{
					if(item.name!='常用'&&item.name!='Commonly Used'){
						arr.push(item.name)
					}
				})
				return arr
			},
		},
		data() {
			return {
				searchValue: '',
				showDialog:false,

				isSearch:false,
				searchList:[],
			}
		},
		methods: {
			close() {
				// 搜索初始化
				this.isSearch=false;
				this.$emit('close');
			},
			handleSearch() {
				let text = this.searchValue.trim().toLowerCase();
				if(text==''){
					// 搜索初始化
					this.isSearch=false;
				}else{
					var searchList=[];
					this.webList.forEach(item=>{
						if(item.name!='常用'&&item.name!='Commonly Used'){
							item.list.forEach(it=>{
								if(RegExp(text).test(it.name.toLowerCase())){
									searchList.push(it)
								}
							})
						}
					})
					this.searchList=searchList;
					this.isSearch=true;
				}
			},
			// , index, subindex
			handleSelect(text, web,id,web_type) {
				// 搜索初始化
				this.isSearch=false;
				let obj = {
					'text': text,
					'web': web,
					'web_id': id,
					'web_type': web_type,
				}
				this.$emit('select', obj);
			},
		},
		
		watch: {
			show(val, oldVal) {
				if(val==oldVal){
					return
				}
				this.searchValue = '';
				this.showDialog=val;
				// console.log(this.webList)
			},
			showDialog(val, oldVal) {
				if(val==oldVal){
					return
				}
				this.$emit('update:show', this.showDialog)
			},
		}
	})
</script>

<style lang="stylus" scoped>
	@import '~@/assets/style/var.styl';
	.exchange_dialog {
		/deep/ .el-dialog{
			width 920px
			@media screen and (max-width: 993px) {
				width 90%
			}
			.el-dialog__header {
				display none
			}
			.el-dialog__body {
				padding 0
			}
		}
	}
	
	.exchange_form {
		.head {
			color var(--white)
			height 56px
			padding 0 24px
			border-bottom 1px solid var(--border2)
			flexbox()
			align-items center
			justify-content space-between
			.title {
				font-size 16px
			}
			.search {
				width 160px
				/deep/ .el-input__inner {
					color var(--white)
					height 32px
					padding 0 10px
					border-color var(--border2)
					background none
					radius(2px)
					line-height 32px
				}
				/deep/ .el-input__icon {
					line-height 32px
				}
			}
		}
		.cont {
			color var(--normal)
			padding 10px
			.cont_box {
				padding 14px 0px 14px 30px
				max-height 435px
				overflow-y auto
				@media screen and (max-width: 750px) {
					padding 14px 0px 14px 14px
				}
			}
			.list {
				margin-bottom 16px
				// &.active {
				// 	.taglist {
				// 		.active {
				// 			color var(--active)
				// 			border-color var(--active)
				// 		}
				// 	}
				// }
				
				.select {
					color var(--active)
					border-color var(--active)
				}
				.title {
					margin-bottom 16px
				}
				.taglist {
					flexbox()
					flex-wrap wrap
					span {
						padding 0 5px
						min-width 87px
						height 32px
						border 1px solid var(--border2)
						boxSize(border-box)
						radius(2px)
						cursor pointer
						margin 0 0.16rem 0.16rem 0
						flexbox()
						align-items center
						justify-content center
						&:hover {
							color var(--active)
						}
					}
				}
			}
			
			// 搜索结果
			.cont_box2{
				padding 14px 0px 14px 30px
				overflow-y auto

				flexbox()
				flex-wrap wrap
				.item {
					padding 0 5px
					min-width 87px
					height 32px
					border 1px solid var(--border2)
					boxSize(border-box)
					radius(2px)
					cursor pointer
					margin 0 0.16rem 0.16rem 0
					flexbox()
					align-items center
					justify-content center
					&:hover {
						color var(--active)
					}
				}
			}
		}
	}

	// 移动端判定
	//动作面板
	/deep/ .van-action-sheet__content{
		padding: 4px 0 12px
		.search_box{
			padding 0 16px
		}
		.cont_box,
		.van-index-anchor,
		.index_box .van-cell{
			padding-left 28px
			padding-right 28px
		}
		.van-cell:after{
			left: 28px
			right: 28px
		}
	}
	.content_phone{
		.cont_box{
			.commonly{
				margin-top 10px
				.title{
					font-size: 0.26rem;
					color: var(--active);
					margin-bottom: 0.2rem
				}
				.taglist{
					display: flex
					flex-wrap: wrap
					span {
						display: flex
						align-items: center
						justify-content: center
						background: var(--phoneTagBarBg);
						padding: 0 0.1rem
						min-width: 1.1rem
						margin-right: 0.2rem
						margin-bottom: 0.2rem
						border-radius: 4px;
						min-height: 0.42rem;
						color: var(--white)
					}
				}
			}
		}
	}
	
	.actionSheet{
		position relative
	}
</style>
