<template>
<div class="mainBox">
	<!-- 账号列表 -->
	<div class="con" v-if="isMobile==0">
		<!-- PC及适配 -->
		<div class="container">
			<div class="header">
				<p v-if="accountList.length">{{$t('account.title')}}</p>
				<span v-else>{{$t('account.create')}}</span>
			</div>
			
			<div class="content">
				<div class="content-container">
					<template v-if="accountList.length">
						<div class="account_box">
							<div class="btnBox">
								<div class="g_btn btn" @click="handleCreate">{{$t('account.bind')}}</div>
							</div>
							<div class="listbox">
								<div class="item" v-for="(item,index) in accountList" :key="index" :class="{active: activeItem == index}">
									<div>
										<div class="caption">
											<div class="left">{{item.name}}</div>
											<div class="right g_btn" @click="jumpSubManage(item)" v-if="item.wallet_type==1||item.wallet_type==2">{{$t('common.subManage')}}</div>
										</div>
										<div class="bd">
											<div class="li">
												<div class="title">{{$t('common.exchange')}}</div>
												<div class="text">{{item.web_name}}</div>
											</div>
											<div class="li">
												<div class="title" v-if="
													item.web=='pancake'||
													item.web=='mdex'||
													item.web=='uniswap'||
													item.web=='cherry'||
													item.web=='quickswap'||
													item.web=='fiveswap'">{{$t('account.walletAddress')}}</div>
												<div class="title" v-else>API Key</div>
												<div class="text">{{item.key}}</div>
											</div>
											
											<div class="li">
												<div class="title" v-if="
													item.web=='pancake'||
													item.web=='mdex'||
													item.web=='uniswap'||
													item.web=='cherry'||
													item.web=='quickswap'||
													item.web=='fiveswap'">{{$t('account.privateKey')}}</div>
												<div class="title" v-else-if="
													item.web=='ok'||
													item.web=='99ex'||
													item.web=='73jie'||
													item.web=='bibr'||
													item.web=='kucoin'||
													item.web=='fubt'||
													item.web=='exnow'||
													item.web=='coinmex'||
													item.web=='zt'||
													item.web=='winex_old'||
													item.web=='bh'||
													item.web=='utex'||
													item.web=='un'||
													item.web=='ocx_gl'||
													item.web=='thunderex'||
													item.web=='exnow'||
													item.web=='bitmart'||
													item.web=='bitget'||
													!item.web
												">Secret Key</div>
												<div class="title" v-else>Api Secret</div>
												<div class="text">{{item.secret}}</div>
											</div>

											<div class="li">
												<div class="title" v-if="
													item.web=='ok'||
													item.web=='99ex'||
													item.web=='73jie'||
													item.web=='bitget'
												">Passphrase</div>
												<div class="title" v-else-if="item.web=='bibr'">MerchantID</div>
												<div class="title" v-else-if="item.web=='kucoin'">{{$t('account.apiPWD')}}</div>
												<div class="title" v-else-if="item.web=='fubt'">{{$t('account.payPWD')}}</div>
												<div class="title" v-else-if="
													item.web=='exnow'||
													item.web=='coinmex'||
													item.web=='zt'||
													item.web=='winex_old'||
													item.web=='bh'||
													item.web=='utex'||
													item.web=='un'||
													item.web=='ocx_gl'||
													item.web=='thunderex'
												">UID</div>
												<div class="title" v-else-if="item.web=='bitmart'">{{$t('account.apiName')}}</div>
												<div class="title" v-else>Account-ID</div>
												<div class="text">{{item.account_id}}</div>
											</div>
											<div class="li">
												<div class="title">{{$t('common.operation')}}</div>
												<div class="text">
													<span class="btn change" @click="handleChange(index)">{{$t('common.modify')}}</span>
													<span class="btn delete" @click="handleDelete(index)">{{$t('common.del')}}</span>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</template>
					<el-empty v-else :image="emptyImg" :image-size="120" :description="$t('account.toCreate')">
						<div class="g_btn btn" @click="handleCreate">{{$t('account.newAccount')}}</div>
					</el-empty>
				</div>
			</div>
		</div>
			
		<!-- 创建账户、修改账户 -->
		<el-dialog :title="form.account_id?$t('account.editAcc'):$t('account.create')" :visible.sync="isPop" :show-close="false" center @close="closePop">
			<div class="account_form">
				<div class="form">
					<div class="item">
						<div class="title">{{$t('account.accountName')}}</div>
						<div class="text">
							<input v-model="form.name" type="text" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item">
						<div class="title">{{$t('common.exchange')}}</div>
						<div class="text">
							<div class="select" @click="showExchange">
								<input v-model="form.exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
								<i class="iconfont el-icon-arrow-down"></i>
							</div>
						</div>
					</div>
					<div class="item" :class="{disabled: true}">
						<div class="title">{{$t('account.network')}}</div>
						<div class="text">
							<el-select
								v-model="form.wifi"
								:disabled="true"
								:placeholder="$t('common.select')"
								class="select"
							>
								<el-option
									v-for="(item, index) in wifiOptions"
									:key="index"
									:label="item.label"
									:value="item.value"
								></el-option>
							</el-select>
						</div>
					</div>
					<div class="item">
						<div class="title" v-if="
							web=='pancake'||
							web=='mdex'||
							web=='uniswap'||
							web=='cherry'||
							web=='quickswap'||
							web=='fiveswap'">{{$t('account.walletAddress')}}</div>
						<div class="title" v-else>API Key</div>
						<div class="text">
							<input v-model="form.key" type="text" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item">
						<div class="title" v-if="
							web=='pancake'||
							web=='mdex'||
							web=='uniswap'||
							web=='cherry'||
							web=='quickswap'||
							web=='fiveswap'">{{$t('account.privateKey')}}</div>
						<div class="title" v-else-if="
							web=='ok'||
							web=='99ex'||
							web=='73jie'||

							web=='bibr'||

							web=='kucoin'||

							web=='fubt'||

							web=='exnow'||
							web=='coinmex'||
							web=='zt'||
							web=='winex_old'||
							web=='bh'||
							web=='utex'||
							web=='un'||
							web=='ocx_gl'||
							web=='thunderex'||

							web=='exnow'||

							web=='bitmart'||
							web=='bitget'||
							!web
						">Secret Key</div>
						<div class="title" v-else>Api Secret</div>
						<div class="text">
							<input v-model="form.secret" type="password" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<!-- 附加项 -->
					<div class="item" v-if="
						web=='ok'||
						web=='99ex'||
						web=='73jie'||
						web=='bitget'
					">
						<div class="title">Passphrase</div>
						<div class="text">
							<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item" v-else-if="web=='bibr'">
						<div class="title">MerchantID</div>
						<div class="text">
							<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item" v-else-if="web=='kucoin'">
						<div class="title">{{$t('account.apiPWD')}}</div>
						<div class="text">
							<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item" v-else-if="web=='fubt'">
						<div class="title">{{$t('account.payPWD')}}</div>
						<div class="text">
							<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item" v-else-if="
					web=='exnow'||
					web=='coinmex'||
					web=='zt'||
					web=='winex_old'||
					web=='bh'||
					web=='utex'||
					web=='un'||
					web=='ocx_gl'||
					web=='thunderex'
					">
						<div class="title">UID</div>
						<div class="text">
							<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item" v-else-if="
					web=='bitmart'
					">
						<div class="title">{{$t('account.apiName')}}</div>
						<div class="text">
							<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<!-- 邮箱验证码 -->
                    <!-- <div class="item">
                        <div class="title">{{$t('common.verification')}}</div>
                        <div class="text">
                            <div class="inp_code">
                                <el-input
                                    :placeholder="$t('common.inputVerification')"
                                    type="text"
                                    v-model="form.sms_code"
                                />
                                <div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
                                <div v-else class="g_btn btn" @click="handleSendCode">
                                    <template v-if="firstSend">{{$t('common.sendVerification')}}</template>
                                    <template v-else>{{$t('common.resend')}}</template>
                                </div>
                            </div>
                        </div>
                    </div> -->
				</div>
				<div class="tips" v-if="
					web=='pancake'||
					web=='mdex'||
					web=='uniswap'||
					web=='cherry'||
					web=='quickswap'||
					web=='fiveswap'||
					!web">
					<p>{{$t('account.whiteList')}}：&lt;<span>47.242.71.169,47.244.207.193,8.210.14.29</span>&gt;</p>
					<p>{{$t('account.tip')}}</p>
				</div>
				<div class="foot" v-show="!isEdit">
					<div class="g_btn btn submit" @click="confirmCreate">{{$t('common.confirm')}}</div>
				</div>
				<div class="foot" v-show="isEdit">
					<div class="g_btn btn" @click="closePop">{{$t('common.back')}}</div>
					<div class="g_btn btn submit" @click="confirmCreate">{{$t('common.save')}}</div>
				</div>
			</div>
		</el-dialog>

		<!-- 删除账户 -->
		<el-dialog :title="$t('account.delAcc')" :visible.sync="showDel" :show-close="false" center @close="closeDelPop">
			<div class="account_form">
				<div class="form">
					<div class="item">
						<div class="title">{{$t('account.accountName')}}</div>
						<div class="text">
							<input v-model="form.name" type="text" readonly :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item">
						<div class="title">{{$t('common.exchange')}}</div>
						<div class="text">
							<div class="select">
								<input v-model="form.exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
								<i class="iconfont el-icon-arrow-down"></i>
							</div>
						</div>
					</div>
					<!-- 邮箱验证码 -->
                    <div class="item">
                        <div class="title">{{$t('common.verification')}}</div>
                        <div class="text">
                            <div class="inp_code">
                                <el-input
                                    :placeholder="$t('common.inputVerification')"
                                    type="text"
                                    v-model="form.sms_code"
                                />
                                <div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
                                <div v-else class="g_btn btn" @click="handleSendCode">
                                    <template v-if="firstSend">{{$t('common.sendVerification')}}</template>
                                    <template v-else>{{$t('common.resend')}}</template>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
				<div class="foot">
					<div class="g_btn btn" @click="closeDelPop">{{$t('common.back')}}</div>
					<div class="g_btn btn submit" @click="handleDelAcc">{{$t('common.del')}}</div>
				</div>
			</div>
		</el-dialog>
		
		<!-- 创建账户成功提示 -->
		<el-dialog class="exchange_dialog" :visible.sync="isOk" :show-close="false" @close="isOk = false">
			<div class="account_ok">
				<div class="icon"><img src="@/assets/images/ok.png" /></div>
				<div class="text">
					<h2>{{$t('account.success')}}</h2>
					<p class="tips">{{$t('account.sub')}}</p>
				</div>
				<div class="foot">
					<div class="g_btn btn active" @click="confirmOk">{{$t('common.continueAdd')}}</div>
					<div class="g_btn btn" @click="cancelOk">{{$t('common.backlist')}}</div>
				</div>
			</div>
		</el-dialog>
	</div>
	<div class="con" v-else-if="isMobile==1">
		<!-- 移动端判定 -->
		<div class="phoneContainer">
			<template v-if="accountList.length">
				<div class="account_box">
					<div class="listbox">
						<div class="item" v-for="(item,index) in accountList" :key="index" :class="{active: activeItem == index}">
							<div>
								<div class="caption">
									<div class="left">{{item.name}}</div>
									<div class="right g_btn" @click="jumpSubManage(item)" v-if="item.wallet_type==1||item.wallet_type==2">{{$t('common.subManage')}}</div>
								</div>
								<div class="bd">
									<div class="li">
										<div class="title">{{$t('common.exchange')}}</div>
										<div class="text">{{item.web_name}}</div>
									</div>
									<div class="li">
										<div class="title" v-if="
											item.web=='pancake'||
											item.web=='mdex'||
											item.web=='uniswap'||
											item.web=='cherry'||
											item.web=='quickswap'||
											item.web=='fiveswap'">{{$t('account.walletAddress')}}</div>
										<div class="title" v-else>API Key</div>
										<div class="text">{{item.key}}</div>
									</div>
									<div class="li">
										<div class="title" v-if="
											item.web=='pancake'||
											item.web=='mdex'||
											item.web=='uniswap'||
											item.web=='cherry'||
											item.web=='quickswap'||
											item.web=='fiveswap'">{{$t('account.privateKey')}}</div>
										<div class="title" v-else-if="
											item.web=='ok'||
											item.web=='99ex'||
											item.web=='73jie'||
											item.web=='bibr'||
											item.web=='kucoin'||
											item.web=='fubt'||
											item.web=='exnow'||
											item.web=='coinmex'||
											item.web=='zt'||
											item.web=='winex_old'||
											item.web=='bh'||
											item.web=='utex'||
											item.web=='un'||
											item.web=='ocx_gl'||
											item.web=='thunderex'||
											item.web=='exnow'||
											item.web=='bitmart'||
											item.web=='bitget'||
											!item.web
										">Secret Key</div>
										<div class="title" v-else>Api Secret</div>
										<div class="text">{{item.secret}}</div>
									</div>
									<div class="li">
										<div class="title" v-if="
											item.web=='ok'||
											item.web=='99ex'||
											item.web=='73jie'||
											item.web=='bitget'
										">Passphrase</div>
										<div class="title" v-else-if="item.web=='bibr'">MerchantID</div>
										<div class="title" v-else-if="item.web=='kucoin'">{{$t('account.apiPWD')}}</div>
										<div class="title" v-else-if="item.web=='fubt'">{{$t('account.payPWD')}}</div>
										<div class="title" v-else-if="
											item.web=='exnow'||
											item.web=='coinmex'||
											item.web=='zt'||
											item.web=='winex_old'||
											item.web=='bh'||
											item.web=='utex'||
											item.web=='un'||
											item.web=='ocx_gl'||
											item.web=='thunderex'
										">UID</div>
										<div class="title" v-else-if="item.web=='bitmart'">{{$t('account.apiName')}}</div>
										<div class="title" v-else>Account-ID</div>
										<div class="text">{{item.account_id}}</div>
									</div>
									<div class="li">
										<div class="operation">
											<span class="g_btn btn change" @click="handleChange(index)">{{$t('common.modify')}}</span>
											<span class="g_btn btn delete" @click="handleDelete(index)">{{$t('common.del')}}</span>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</template>
			<el-empty class="isPhone" v-else :image="emptyImg_phone" :image-size="120" :description="$t('account.toCreate')">
				<div class="g_btn btn" @click="handleCreate">{{$t('account.newAccount')}}</div>
			</el-empty>
		</div>
		
		<!-- 创建账户、修改账户 -->
		<van-action-sheet 
		v-model="isPop" 
		:title="form.account_id?$t('account.editAcc'):$t('account.create')" 
		:closeable="false"
		@closed="closePop">
		
			<div class="account_form_phone">
				<div class="form">
					<div class="item">
						<div class="title">{{$t('account.accountName')}}</div>
						<div class="text">
							<input v-model="form.name" type="text" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item">
						<div class="title">{{$t('common.exchange')}}</div>
						<div class="text">
							<div class="select" @click="showExchange">
								<input v-model="form.exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
								<i class="iconfont el-icon-arrow-down"></i>
							</div>
						</div>
					</div>
					<div class="item" :class="{disabled: true}">
						<div class="title">{{$t('account.network')}}</div>
						<div class="text">
							<el-select
								v-model="form.wifi"
								:disabled="true"
								:placeholder="$t('common.select')"
								class="select"
							>
								<el-option
									v-for="(item, index) in wifiOptions"
									:key="index"
									:label="item.label"
									:value="item.value"
								></el-option>
							</el-select>
						</div>
					</div>
					<div class="item">
						<div class="title" v-if="
							web=='pancake'||
							web=='mdex'||
							web=='uniswap'||
							web=='cherry'||
							web=='quickswap'||
							web=='fiveswap'">{{$t('account.walletAddress')}}</div>
						<div class="title" v-else>API Key</div>
						<div class="text">
							<input v-model="form.key" type="text" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item">
						<div class="title" v-if="
							web=='pancake'||
							web=='mdex'||
							web=='uniswap'||
							web=='cherry'||
							web=='quickswap'||
							web=='fiveswap'">{{$t('account.privateKey')}}</div>
						<div class="title" v-else>Secret Key</div>
						<div class="text">
							<input v-model="form.secret" type="password" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<!-- 附加项 -->
					<div class="item" v-if="
						web=='ok'||
						web=='99ex'||
						web=='73jie'||
						web=='bitget'
					">
						<div class="title">Passphrase</div>
						<div class="text">
							<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item" v-else-if="web=='bibr'">
						<div class="title">MerchantID</div>
						<div class="text">
							<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item" v-else-if="web=='kucoin'">
						<div class="title">{{$t('account.apiPWD')}}</div>
						<div class="text">
							<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item" v-else-if="web=='fubt'">
						<div class="title">{{$t('account.payPWD')}}</div>
						<div class="text">
							<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item" v-else-if="
					web=='exnow'||
					web=='coinmex'||
					web=='zt'||
					web=='winex_old'||
					web=='bh'||
					web=='utex'||
					web=='un'||
					web=='ocx_gl'||
					web=='thunderex'
					">
						<div class="title">UID</div>
						<div class="text">
							<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item" v-else-if="web=='bitmart'">
						<div class="title">{{$t('account.apiName')}}</div>
						<div class="text">
							<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<!-- 邮箱验证码 -->
                    <!-- <div class="item">
                        <div class="title">{{$t('common.verification')}}</div>
                        <div class="text">
                            <div class="inp_code">
                                <el-input
                                    :placeholder="$t('common.inputVerification')"
                                    type="text"
                                    v-model="form.sms_code"
                                />
                                <div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
                                <div v-else class="g_btn btn" @click="handleSendCode">
                                    <template v-if="firstSend">{{$t('common.sendVerification')}}</template>
                                    <template v-else>{{$t('common.resend')}}</template>
                                </div>
                            </div>
                        </div>
                    </div> -->
				</div>
				<div class="tips" v-if="
					web=='pancake'||
					web=='mdex'||
					web=='uniswap'||
					web=='cherry'||
					web=='quickswap'||
					web=='fiveswap'||
					!web">
					<p>{{$t('account.whiteList')}}：&lt;<span>47.242.71.169,47.244.207.193,8.210.14.29</span>&gt;</p>
					<p>{{$t('account.tip')}}</p>
				</div>
				<div class="foot" v-show="!isEdit">
					<div class="g_btn btn" @click="closePop">{{$t('common.back')}}</div>
					<div class="g_btn btn submit" @click="confirmCreate">{{$t('common.confirm')}}</div>
				</div>
				<div class="foot" v-show="isEdit">
					<div class="g_btn btn" @click="closePop">{{$t('common.back')}}</div>
					<div class="g_btn btn submit" @click="confirmCreate">{{$t('common.save')}}</div>
				</div>
			</div>
		</van-action-sheet>

		<!-- 删除账户 -->
		<van-action-sheet 
		v-model="showDel" 
		:title="$t('account.delAcc')" 
		:closeable="false"
		@closed="closeDelPop">
		
			<div class="account_form_phone">
				<div class="form">
					<div class="item">
						<div class="title">{{$t('account.accountName')}}</div>
						<div class="text">
							<input v-model="form.name" type="text" readonly :placeholder="$t('common.input')" class="inp" />
						</div>
					</div>
					<div class="item">
						<div class="title">{{$t('common.exchange')}}</div>
						<div class="text">
							<div class="select">
								<input v-model="form.exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
								<i class="iconfont el-icon-arrow-down"></i>
							</div>
						</div>
					</div>
					<!-- 邮箱验证码 -->
                    <div class="item">
                        <div class="title">{{$t('common.verification')}}</div>
                        <div class="text">
                            <div class="inp_code">
                                <el-input
                                    :placeholder="$t('common.inputVerification')"
                                    type="text"
                                    v-model="form.sms_code"
                                />
                                <div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
                                <div v-else class="g_btn btn" @click="handleSendCode">
                                    <template v-if="firstSend">{{$t('common.sendVerification')}}</template>
                                    <template v-else>{{$t('common.resend')}}</template>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
				<div class="foot">
					<div class="g_btn btn" @click="closeDelPop">{{$t('common.back')}}</div>
					<div class="g_btn btn submit" @click="handleDelAcc">{{$t('common.del')}}</div>
				</div>
			</div>
		</van-action-sheet>
		
		<!-- 创建账户成功提示 -->
		<el-dialog class="exchange_dialog" :visible.sync="isOk" :show-close="false" @close="isOk = false">
			<div class="account_ok">
				<div class="icon"><img src="@/assets/images/ok.png" /></div>
				<div class="text">
					<h2>{{$t('account.success')}}</h2>
					<p class="tips">{{$t('account.sub')}}</p>
				</div>
				<div class="foot">
					<div class="g_btn btn active" @click="confirmOk">{{$t('common.continueAdd')}}</div>
					<div class="g_btn btn" @click="cancelOk">{{$t('common.backlist')}}</div>
				</div>
			</div>
		</el-dialog>
	
	</div>

	<!-- 绑定邮箱 -->
	<el-dialog :title="$t('user.bindEmail')" :visible.sync="showEmail" :show-close="false" center :before-close="emailCancel">
		<div class="add_form">
			<div class="bind_form">
				<div class="item">
					<div class="title">{{$t('common.newEmail')}}</div>
					<div class="text">
						<input
							class="inp"
							:placeholder="$t('common.input')"
							v-model="email_form.email"
						/>
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.verification')}}</div>
					<div class="text">
						<div class="inp_code">
							<input
								class="inp"
								:placeholder="$t('common.inputVerification')"
								type="text"
								v-model="email_form.sms_code"
							/>
							<div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
							<div v-else class="g_btn btn" @click="handleSendCode('email')">
								<template v-if="firstSend">{{$t('common.sendVerification')}}</template>
								<template v-else>{{$t('common.resend')}}</template>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="foot">
				<div class="btn" @click="emailCancel">{{$t('common.cancel')}}</div>
				<div class="btn submit" @click="editEmail">{{$t('common.confirm2')}}</div>
			</div>
		</div>
	</el-dialog>

	<!-- 交易所选择 -->
	<exchange-dialog
		:show.sync="popExchange"
		:web="web"
		@close="closeExchange"
		@select="handleSelect"
	/>
</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'index.styl';
</style>

<script>
	var timer;

	import { Loading } from 'element-ui';
	import ExchangeDialog from '@/components/exchange_not_el.vue';
	// api
	import {getAccountList,setAccountItem,delAccountItem} from '@/api/account' 
    import {sendEmail,updateEmail } from '@/api/user'
	import { mapGetters } from 'vuex'
	import bus from "../bus.js";
	export default ({
		name: 'account',
		components: {
			ExchangeDialog,
		},
		data() {
			return {
				isPop: false,
				isOk: false,
				isEdit: false,
				showDel:false,
				emptyImg: require('@/assets/images/norecord.png'),
				emptyImg_phone: require('@/assets/images/norecord_phone.png'),
				
				activeItem: null,
				
				// 交易所
				popExchange: false,
				web: null,
				
				// 此处数据仅用于测试,请自行获取数据
				accountList: [],
				form: {},
				wifiOptions: [
					{
						label: 'wifi',
						value: 1
					},
					{
						label: 'wifi2',
						value: 2
					},
					{
						label: 'wifi3',
						value: 3
					},
				],
				
				// 邮箱验证
				codeSending: false,
				timeCount: 60,
				firstSend: true,
				// 绑定邮箱
				showEmail: false,
            	email_form:{},
			}
		},
		computed: {
			...mapGetters(["account",'lang','isMobile','email']),
		},
		created(){
			// 获取账号列表
			this.getAccountList()
			bus.$on('add',()=>{
				this.handleCreate()
			})
		},
		methods: {
			// 子钱包管理
			jumpSubManage(item){
				// console.log(item)
				this.$router.push({
					path:"/subManage",
					query:{
						web:item.web,
						exchange:item.web_name,
						// yenUnit1:this.yenUnit1,
						// yenUnit2:this.yenUnit2,
					}
				})
			},
			closePop() {
				this.isPop = false;
				this.$nextTick(()=>{
					this.form = {};
					this.activeItem = null;
					this.web=null;
				})
			},
			// 打开新增弹窗
			handleCreate() {
				if(!this.email){
					this.showEmail=true
					// 打开时 未绑定邮箱
					this.$nextTick(()=>{
						this.$message({
							message: this.$t('tip.notBindEmail'),
							type: 'error',
							center: true,
							customClass: 'shotMsg',
						});
					});
				}else{
					this.isPop = true;
					this.isEdit = false;
					this.form = {};
				}
			},
			// 新增/编辑
			confirmCreate() {
				// console.log(this.form);
				if(!this.form.name){
					this.$message({
						message: this.$t('tip.accountEmpty'),
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.form.web){
					this.$message({
						message: this.$t('tip.selectExchange'),
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.form.key){
					this.$message({
						message: this.$t('common.input') +'API Key！',
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.form.secret){
					this.$message({
						message: this.$t('common.input') +'Secret Key！',
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				// }else if(!this.form.sms_code){
				// 	this.$message({
				// 		message: this.$t('tip.codeEmpty')+'！',
				// 		type: 'error',
				// 		center: true,
				// 		offset: 100,
				// 		customClass: 'shotMsg',
				// 	});
				}else{
					// 附加项
					if(
						(
							this.form.web=='ok'||
							this.form.web=='99ex'||
							this.form.web=='73jie'||
							this.form.web=='bibr'||
							this.form.web=='kucoin'||
							this.form.web=='fubt'||
							this.form.web=='exnow'||
							this.form.web=='coinmex'||
							this.form.web=='zt'||
							this.form.web=='winex_old'||
							this.form.web=='bh'||
							this.form.web=='utex'||
							this.form.web=='un'||
							this.form.web=='ocx_gl'||
							this.form.web=='thunderex'||
							this.form.web=='bitget'
						)&&!this.form.account_id
					){
						this.$message({
							message: this.$t('tip.dataErr'),
							center: true,
							type: 'error',
							offset: 100,
							customClass: 'shotMsg',
						});
					}else{
						// 提交表单信息--关闭弹框--获取新列表
						setAccountItem({
							name:this.form.name,
							web:this.form.web,
							key:this.form.key,
							secret:this.form.secret,
							exchange_account_id:this.form.account_id||0,
							account:this.account,
							// sms_code:this.form.sms_code,
						}).then(res=>{
							if(res==1){
								this.getAccountList();
								if(!this.isEdit){
									this.$message({
										message: this.$t('tip.addSuc'),
										center: true,
										type: 'success',
										offset: 100,
										customClass: 'shotMsg',
									});
									this.$nextTick(() => {
										this.isOk = true;
									})
								}else{
									this.$message({
										message: this.$t('tip.editSuc'),
										center: true,
										type: 'success',
										offset: 100,
										customClass: 'shotMsg',
									});
								}
								this.isPop = false;
							}else{
								if(!isEdit){
									this.$message({
										message: this.$t('tip.addErr'),
										center: true,
										type: 'error',
										offset: 100,
										customClass: 'shotMsg',
									});
								}else{
									this.$message({
										message: this.$t('tip.editErr'),
										center: true,
										type: 'error',
										offset: 100,
										customClass: 'shotMsg',
									});
								}
							}
						})
					}
				}
			},
			// 交易所
			showExchange() {
				this.popExchange = true;
			},
			closeExchange() {
				this.popExchange = false;
			},
			handleSelect(data) {
				this.form.exchange = data.text;
				this.form.web = data.web;
				// this.activeIndex = data.index;
				// this.activeSubIndex = data.subindex;
				this.web=data.web;
				this.popExchange = false;
			},
			// 继续添加
			confirmOk() {
				this.isPop = true;
				this.isOk = false;
			},
			// 返回列表
			cancelOk() {
				this.isOk = false;
			},

			// 打开编辑弹窗
			handleChange(index) {
				if(!this.email){
					this.showEmail=true
					// 打开时 未绑定邮箱
					this.$nextTick(()=>{
						this.$message({
							message: this.$t('tip.notBindEmail'),
							type: 'error',
							center: true,
							customClass: 'shotMsg',
						});
					});
				}else{
					this.activeItem = index;
					this.form =Object.assign({},this.accountList[index])

					this.form.exchange=this.accountList[index].web_name
					this.web=this.accountList[index].web
					// 获取账号信息--显示弹窗
					this.isPop = true;
					this.isEdit = true;
				}
			},
			// 打开删除弹窗
			handleDelete(index) {
				// this.showDel=true;
				this.activeItem = index;
				// this.form =Object.assign({},this.accountList[index])
				// this.form.exchange=this.accountList[index].web_name
				// this.web=this.accountList[index].web
				
				this.$confirm(this.$t('tip.delTip'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.back'),
					center: true,
					showClose: false,
					customClass: 'deleteOk',
				}).then(() => {
					this.activeItem = null;
					// 后台申请删除数据--成功/失败--关闭弹框--获取新列表
					delAccountItem({
						web:this.accountList[index].web,
						account:this.account,
						access_id:this.accountList[index].id,
					}).then(res=>{
						// console.log(res)
						if(res==1){
							this.getAccountList();
							this.$message({
								message: this.$t('tip.delSuc'),
								type: 'success',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
						}else{
							// 删除失败
							this.$message({
								message: this.$t('tip.delErr'),
								type: 'error',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
						}
					})
				}).catch(() => {
					// 取消
					this.activeItem = null;
				});
			},
			closeDelPop(){
				this.showDel=false;
				this.activeItem = null;
			},
			// 删除账号操作
			handleDelAcc(){
				if(!this.form.sms_code){
					this.$message({
						message: this.$t('tip.codeEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else{
					delAccountItem({
						web:this.accountList[this.activeItem].web,
						account:this.account,
						access_id:this.accountList[this.activeItem].id,
						sms_code:this.form.sms_code,
					}).then(res=>{
						// console.log(res)
						if(res==1){
							this.getAccountList();
							this.$message({
								message: this.$t('tip.delSuc'),
								type: 'success',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
							
							this.showDel=false;
							this.activeItem = null;
						}else{
							// 删除失败
							this.$message({
								message: this.$t('tip.delErr'),
								type: 'error',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
						}
					})
				}
			},
			// 获取账号列表
			getAccountList(){
				const loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
			
				// this.$store.dispatch('account/getList')
				getAccountList({account:this.account}).then(res => {
					// console.log(res)
					this.accountList=res
					loading.close();
				}).catch(err=>{
					loading.close();
				});
			},
			// 关闭绑定邮箱弹窗
			emailCancel() {
				this.showEmail = false;
				this.email_form = {};
				
				clearInterval(timer);
				this.codeSending = false;
				this.firstSend = true;
			},
			// 绑定邮箱提交
			editEmail(){
				var emailReg=/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
				// /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
				if(!this.email_form.email){
					this.$message({
						message: this.$t('tip.emailEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!emailReg.test(this.email_form.email) ){
					this.$message({
						message: this.$t('tip.emailVer')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.email_form.sms_code){
					this.$message({
						message: this.$t('tip.codeEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else{
					updateEmail({
						account:this.account,
						email:this.email_form.email,
						sms_code:this.email_form.sms_code,
						type:0,
					}).then(res=>{
						if(res==1){
							this.getInfo()
							// 修改成功
							this.$message({
								message: this.$t('tip.editSuc'),
								type: 'success',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
							this.showEmail = false;
							this.email_form = {};
							
							clearInterval(timer);
							this.codeSending = false;
							this.firstSend = true;
						}else{
							// 修改失败
							this.$message({
								message: this.$t('tip.editErr'),
								type: 'error',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
						}
					})
				}
			},
			// 发送验证码
			handleSendCode(type) {
				if(!this.codeSending){
					if(type=='email'){
						// 修改邮箱
						var emailReg=/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
						// /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
						if(!this.email_form.email){
							this.$message({
								message: this.$t('tip.emailEmpty')+'！',
								type: 'error',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
							return
						}else if(!emailReg.test(this.email_form.email) ){
							this.$message({
								message: this.$t('tip.emailVer')+'！',
								type: 'error',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
							return
						}else{
							// 发送验证码
							this.timeCount = 60;
							this.codeSending = true;
							let that = this;
							this.firstSend = false;
							clearInterval(timer);
							timer = setInterval(() => {
								if(this.timeCount >= 1) {
									that.timeCount -= 1;
								} else {
									clearInterval(timer);
									this.codeSending = false;
								}
							}, 1000);

							sendEmail({
								account:this.account,
								email:this.email_form.email,
								type:2,
							})
						}
					}else{
						// 发送验证码
						this.timeCount = 60;
						this.codeSending = true;
						let that = this;
						this.firstSend = false;
						clearInterval(timer);
						timer = setInterval(() => {
							if(this.timeCount >= 1) {
								that.timeCount -= 1;
							} else {
								clearInterval(timer);
								this.codeSending = false;
							}
						}, 1000);
					
						sendEmail({
							account:this.account,
							email:this.email,
							type:1,
						})
					}
				}
			},
			// 获取详情
			getInfo(){
				const loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				this.$store.dispatch('user/getInfo').then(res=>{
					loading.close();
				}).catch(err=>{
					loading.close();
				})
			},
		},
		beforeDestroy() {
			if(timer){
				clearInterval(timer)
				timer=''
			}
		}
	})
</script>
